import React from 'react';
import styles from './Logo.module.scss';
import logoSmall from '../../images/logo_small.svg';
import logoBig from '../../images/logo_big.svg';

export interface Props {
	className?: string;
	size?: 'small' | 'big';
}

export const Logo = (props: Props) => {
	const size = props.size ?? 'small';
	return (
		<a
			href='http://votr.itacs.de'
			target='_blank'
			rel='noopener noreferrer'
			className={[styles.logo, styles[size], props.className].join(' ')}
		>
			<img src={size === 'small' ? logoSmall : logoBig} alt='VOTR' title={process.env.REACT_APP_VERSION} />
			<span>einfach sicher abstimmen.</span>
		</a>
	);
};
