import React from 'react';
import { ToggleableIcon } from './ToggleableIcon';
import { useTranslation } from 'react-i18next';

export interface Props {
	question: { votesPerMember: number, kind: 'text' | 'choice' };
}

export const QuestionKindIcon = (props: Props) => {
	const { t } = useTranslation();

	return (
		<ToggleableIcon
			icons={[
				{toggled:props.question.kind === 'text', iconName: 'InsertTextBox', text: t('kind.text')},
				{toggled:props.question.votesPerMember > 1, iconName: 'MultiSelect', text: t('kind.multipleChoice')},
				{toggled:props.question.votesPerMember === 1, iconName: 'StatusCircleCheckmark', text: t('kind.singleChoice')},
			]}
		/>
	);
};
