import { store } from '../store';
import { BusinessDataType } from '../store/business/enums';
import { businessLoading, questionsLoaded, questionResultLoaded } from '../store/business/actions';
import { xhr } from '../xhr';
import { IQuestion, IAnswerOption, IAnsweredOption, IQuestionResult } from '../store/business/interfaces';
import { createGuid, anyInArray } from '../shared/utils';
import { VotingService } from './VotingService';
import { clearMessages, showError, showMessage } from '../shared/notifications';
import { MessageType } from '../store/system/enums';
import { AxiosError } from 'axios';

export class QuestionService {
	public static clearQuestion() {
		store.dispatch(questionsLoaded(undefined));
		store.dispatch(questionResultLoaded(undefined));
	}

	public static async onError(err: AxiosError<any>, status: Number) {
		showError(err);
		if (status === 401) {
			VotingService.logout().then(() => {
				window.location.href = '/?error=unauthorized';
			});
		} else if (status === 404) {
			VotingService.logout().then(() => {
				window.location.href = `/?error=surveyNotFound`;
			});
		}
		return Promise.reject();
	}

	public static async reloadAllQuestions(merge: boolean = false) {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Question] || !business.surveyCode || !business.memberLogin) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Question, true));
		try {
			const reloadedQuestions = await this.getQuestions();
			if (reloadedQuestions) {
				if (reloadedQuestions[0].representation === 'question') {
					let reloadedRunningQuestions = reloadedQuestions.map(q => q as IQuestion);
					if(merge) {
						// simply merge elements, don't look at properties (title, desc. etc.) yet
						let mergedQuestions = business.questions || [];
						
						mergedQuestions = mergedQuestions
						.filter(q => reloadedRunningQuestions.some(rq => rq.id === q.id))
						.concat(reloadedRunningQuestions.filter(rq => !mergedQuestions.some(q => q.id === rq.id)))
						.sort((q1, q2) => q1?.title.localeCompare(q2?.title));
						
						if(mergedQuestions.length === 0) mergedQuestions = undefined;

						store.dispatch(questionsLoaded(mergedQuestions));
					}
					else {
						reloadedRunningQuestions = reloadedRunningQuestions?.sort((q1, q2) => q1?.title.localeCompare(q2?.title));
						store.dispatch(questionsLoaded(reloadedRunningQuestions));
					}
					store.dispatch(questionResultLoaded(undefined));
				} else if (reloadedQuestions[0].representation === 'result') {
					store.dispatch(questionResultLoaded(reloadedQuestions[0]));
					store.dispatch(questionsLoaded(undefined));
				}
			} else {
				store.dispatch(questionResultLoaded(undefined));
				store.dispatch(questionsLoaded(undefined));
			}
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Question, false));
		}
	}

	public static async answerQuestion(question: IQuestion, answerOptions: IAnsweredOption[] | undefined, comment: string) {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Answer]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Answer, true));
		const answerOptionIdsAndWeights =
			answerOptions?.map((o) => {
				return { id: o.id, voteWeight: o.voteWeight };
			}) ?? [];
		try {
			await xhr(`Voting/Answer`, {
				method: 'POST',
				data: { answerOptions: answerOptionIdsAndWeights, votingId: question.votingId, questionId: question.id, comment },
				onError: QuestionService.onError,
			});

			let questions = business.questions.map(q=>{
				if(q.id !== question.id) return q;
				return {...q, hasAnswered: (anyInArray(answerOptions) || !!comment), answeredOptions: answerOptionIdsAndWeights, comment};
			});
			store.dispatch(questionsLoaded(questions));
			showMessage('messages.answerSuccess', MessageType.SUCCESS, true);
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Answer, false));
		}
	}

	public static unAnswer(question: IQuestion) {
		const { business } = store.getState();
		let questions = business.questions.map(q => {
			if(q.id !== question.id) return q;
			return {...q, hasAnswered: false};
		});	
		clearMessages();
		store.dispatch(questionsLoaded(questions));
	}

	private static async getQuestions(): Promise<(IQuestion | IQuestionResult)[] | undefined> {
		const response = await xhr(`Voting/Questions`, {
			method: 'GET',
			onError: QuestionService.onError,
		});
		const data = response.data;
		const questions = QuestionService.parseList(data ? data : null);
		return questions;
	}

	private static parseList(data: Array<any>): (IQuestion | IQuestionResult)[] | undefined {
		if (!data) {
			return undefined;
		}
		return data.map(d => this.parseElement(d));
	}

	private static parseElement(data: any): IQuestion | IQuestionResult | undefined {
		if (!data) {
			return undefined;
		}
		const representation = data['representation'];
		if (representation === 'question') {
			const answerOptions = (data['answerOptions'] as []) || [];
			const votesPerMember = parseInt(data['votesPerMember']);
			const userVoteWeight = parseFloat(data['userVoteWeight']);
			const answeredOptions = (data['answeredOptions'] as []) || [];
			return {
				representation: 'question',
				id: data['id'],
				title: data['title'],
				description: data['description'],
				answerOptions: answerOptions.map(
					(a: any) =>
						({
							id: a['id'],
							title: a['title'],
						} as IAnswerOption)
				),
				security: data['security'],
				kind: data['kind'],
				votesPerMember: isNaN(votesPerMember) ? 1 : votesPerMember,
				allowVotesSplitting: !!data['allowVotesSplitting'],
				votingId: createGuid(),
				hasAnswered: !!data['hasAnswered'],
				answeredOptions: answeredOptions.map((ao: any) => ({
					id: ao['answerOptionId'],
					voteWeight: parseFloat(ao['voteWeight']) ? parseFloat(ao['voteWeight']) : 1,
				})),
				userVoteWeight: isNaN(userVoteWeight) ? 1 : userVoteWeight,
				comment: data['comment'],
			} as IQuestion;
		} else {
			const votesPerMember = parseInt(data['votesPerMember']);
			return {
				representation: 'result',
				description: data['description'],
				title: data['title'],
				security: data['security'],
				answerOptions: data['answerOptions'] || [],
				allowVotesSplitting: !!data['allowVotesSplitting'],
				votesPerMember: isNaN(votesPerMember) ? 1 : votesPerMember,
				comments: data['comments'] || [],
				kind: data['kind']
			} as IQuestionResult;
		}
	}
}
