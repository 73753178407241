import styles from '../../routes/Voting.module.scss';  // TODO: use own module.scss

import React, { useState } from 'react';
import { IQuestion } from '../../store/business/interfaces';
import { useTranslation } from 'react-i18next';
import { Icon, PrimaryButton } from 'office-ui-fabric-react';
import { SidePanel } from './SidePanel';

export interface Props {
	questions: IQuestion[];
	selectedQuestion: IQuestion;
	onSelectionChanged: (question: IQuestion) => void;
}

export const MultipleQuestionsToolbar = (props: Props) => {
	const { t } = useTranslation();
	const [showSelector, setShowSelector] = useState(false);

	const selectQuestion = (question: IQuestion) => {
		props.onSelectionChanged(question);
		setShowSelector(false);
	};

	if(!props.questions || props.questions.length < 2) return (<></>);

	return (
		<div className={styles.multipleQuestionsToolbar}>
			<div className={styles.marginContainer}>
				<div className={styles.marginContainerInner}>
					<div className={styles.questionToolbarInner}>
						<span className={styles.runningQuestionsCircle}>{props.questions.length}</span>
						<span className={styles.runningQuestionsLabel}>{t('selector.activeQuestions')}</span>
						<PrimaryButton
							className={styles.switchButton}
							iconProps={{ iconName: 'Switch' }}
							text={t('selector.switch')}
							onClick={()=>{setShowSelector(true);}}
						/>
					</div>
				</div>
			</div>
			<SidePanel 
				title={t('selector.menu')}
				visible={showSelector}
				onClose={()=>setShowSelector(false)}
				renderMenu={()=>{ return (
					<div className={[styles.questionSelector, showSelector ? styles.open : ''].join(' ')}>
						{props.questions && props.questions.map((question) => { return (
							<div className={[styles.questionSelectorOptionContainer, question.id === props.selectedQuestion?.id ? styles.active : ''].join(' ')} onClick={() => {selectQuestion(question)}}>
								<div className={styles.questionSelectorOption}>
									{question.hasAnswered && (<Icon className={[styles.status, styles.answered].join(' ')} iconName='SkypeCircleCheck' />)}
									{!question.hasAnswered && (<Icon className={styles.status} iconName='LocationCircle' />)}
									<div className={styles.title}><span>{question.title}</span></div>
									{question.id !== props.selectedQuestion?.id && (
										<div className={styles.switch}><Icon iconName='Switch' /></div>
									)}
								</div>
							</div>
						)})}
					</div>
				)}}
			/>
		</div>
	)
};