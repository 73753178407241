import { store } from '../store';
import { SystemLoading } from '../store/system/enums';
import { systemLoading, configLoaded } from '../store/system/actions';
import { xhr, appXhr } from '../xhr';
import { SessionService } from './SessionService';
import { showError } from '../shared/notifications';
import { QuestionService } from './QuestionService';

export class SystemService {
	public static async getApiPath(): Promise<string> {
		const response = await appXhr(`api/v1/Config`, {
			method: 'GET',
			onError: (ex, status) => {
				showError(ex);
				return Promise.reject();
			},
		});
		return response.data.apiUrl;
	}

	public static async ensureConfig(apiBasePath: string, forceReload: boolean = false): Promise<void> {
		await SessionService.ensureSession();
		const { system } = store.getState();
		if ((!forceReload && system.title !== undefined) || system.loading[SystemLoading.Config]) {
			return;
		}
		if (!forceReload) {
			store.dispatch(configLoaded(null, null, null, apiBasePath, null));
		}
		store.dispatch(systemLoading(SystemLoading.Config, true));
		try {
			const response = await xhr(`System/Config`, {
				method: 'GET',
				onError: QuestionService.onError,
			});
			store.dispatch(
				configLoaded(
					response.data['title'],
					response.data['version'],
					response.data['userId'],
					apiBasePath,
					response.data['apiKey']
				)
			);
		} finally {
			store.dispatch(systemLoading(SystemLoading.Config, false));
		}
	}
}
