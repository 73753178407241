import React, { useEffect, useRef } from 'react';
import styles from './App.module.scss';
import { BrowserRouter } from 'react-router-dom';
import { Hub } from './routes/Hub';
import { store, AppState } from './store';
import { Provider, useSelector } from 'react-redux';
import { Messages } from './shared/components/atoms/Messages';
import { SystemService } from './services/SystemService';
import { ProgressIndicator, FontIcon } from 'office-ui-fabric-react';
import { useDoubleTap } from './hooks/useDoubleTap';

export interface Props {
	reversion: number;
	apiBasePath: string;
}

const InnerApp = (props: Props) => {
	const reversion = useSelector((state: AppState) => state.system.reversion);
	const refRoot = useRef<HTMLDivElement>();
	const doubleTap = useDoubleTap();

	useEffect(() => {
		refRoot?.current?.addEventListener('touchstart', doubleTap, { passive: false });
	});

	SystemService.ensureConfig(props.apiBasePath);

	const calcRealHeight = () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	};
	window.addEventListener('resize', () => {
		calcRealHeight();
	});
	calcRealHeight();

	if (reversion === null || reversion === undefined) {
		return (
			<>
				<ProgressIndicator label='Bitte warten' styles={{ root: { margin: '10px' } }} />
			</>
		);
	} else if (reversion !== props.reversion) {
		return (
			<div>
				<h3>Tool veraltet!</h3>
				<FontIcon iconName='Warning' />
				<span>Bitte aktualisieren Sie das Tool.</span>
			</div>
		);
	}

	return (
		<div className={styles.App} ref={refRoot}>
			<BrowserRouter>
				<Hub />
			</BrowserRouter>
		</div>
	);
};

export const App = (props: Props) => {
	return (
		<Provider store={store}>
			<InnerApp {...props} />
			<Messages />
		</Provider>
	);
};
