import React, { useCallback, useEffect, useState } from 'react';
import styles from './CheckboxWithCounter.module.scss';
import { DefaultButton, Checkbox } from 'office-ui-fabric-react';
import { IAnsweredOption, IAnswerOption } from '../../store/business/interfaces';
import { parseFloatPrecise } from '../../shared/utils';

export interface Props {
	disabled: boolean;
	option: IAnswerOption;
	answer?: IAnsweredOption;
	remainingVotes: number;
	userVoteWeight: number;
	remainingAnswers: number;
	showCounter: boolean;
	initialVoteWeight: number;
	onChange: (answerId: string, amount: number) => void;
}

export const CheckboxWithCounter = (props: Props) => {
	const [numberOfOptionVotes, setNumberOfOptionVotes] = useState<number>(props.answer?.voteWeight || 0);
	const maxNumberOfOptionVotes = parseFloatPrecise(Math.min(props.remainingVotes + (props.answer?.voteWeight || 0), props.userVoteWeight));

	const updateOptionVotes = useCallback(
		(newNumberOfOptionVotes: number) => {
			newNumberOfOptionVotes = parseFloatPrecise(newNumberOfOptionVotes);
			if (newNumberOfOptionVotes !== numberOfOptionVotes) {
				if (newNumberOfOptionVotes > maxNumberOfOptionVotes) {
					newNumberOfOptionVotes = maxNumberOfOptionVotes;
				}
				if (newNumberOfOptionVotes < 0) {
					newNumberOfOptionVotes = 0;
				}

				setNumberOfOptionVotes(newNumberOfOptionVotes);
				props.onChange(props.option.id, newNumberOfOptionVotes);
			}
		},
		[maxNumberOfOptionVotes, numberOfOptionVotes, props]
	);

	useEffect(() => {
		if (!props.answer) {
			updateOptionVotes(0);
		}
	}, [props.answer, updateOptionVotes]);

	return (
		<div className={styles.checkboxWithCounterContainer}>
			<>
				<Checkbox
					key={props.option.id}
					label={props.option.title}
					value={props.option.id}
					disabled={
						props.disabled ||
						(numberOfOptionVotes === 0 && (maxNumberOfOptionVotes === 0 || props.remainingAnswers === 0))
					}
					onChange={(evt, isChecked) => {
						const amount = isChecked ? props.initialVoteWeight : 0;
						updateOptionVotes(amount);
					}}
					checked={numberOfOptionVotes > 0}
					className={styles.answerContainer}
				/>
				{props.showCounter ? (
					<>
						<DefaultButton
							text='-'
							disabled={props.disabled || numberOfOptionVotes <= 0}
							onClick={() => {
								updateOptionVotes(numberOfOptionVotes - 1);
							}}
							className={styles.counterButton}
						/>
						<div
							className={[styles.counter, numberOfOptionVotes <= 0 || props.disabled ? styles.disabled : ''].join(' ')}
						>
							<span>{numberOfOptionVotes}</span>
						</div>
						<DefaultButton
							text='+'
							disabled={props.disabled || numberOfOptionVotes === maxNumberOfOptionVotes}
							onClick={() => {
								updateOptionVotes(numberOfOptionVotes + 1);
							}}
							className={styles.counterButton}
						/>
					</>
				) : (
					<></>
				)}
			</>
		</div>
	);
};
