import { IBusinessActionTypes, DATA_LOADING, SURVEY_LOADED, QUESTIONS_LOADED, MEMBER_LOADED, RESULT_LOADED } from './types';

import { BusinessDataType } from './enums';
import { ISurvey, IQuestion, IQuestionResult } from './interfaces';

export const businessLoading = (loading: BusinessDataType, isLoading: boolean): IBusinessActionTypes => {
	return {
		type: DATA_LOADING,
		loading,
		isLoading,
	};
};

export const surveyLoaded = (survey: ISurvey): IBusinessActionTypes => {
	return {
		type: SURVEY_LOADED,
		survey,
	};
};

export const questionsLoaded = (questions?: IQuestion[]): IBusinessActionTypes => {
	return {
		type: QUESTIONS_LOADED,
		questions,
	};
};

export const questionResultLoaded = (questionResult?: IQuestionResult): IBusinessActionTypes => {
	return {
		type: RESULT_LOADED,
		questionResult,
	};
};


export const memberLoaded = (memberLogin?: string, surveyCode?: string, connection?: string): IBusinessActionTypes => {
	return { type: MEMBER_LOADED, memberLogin, surveyCode, connection };
};
