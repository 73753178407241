import styles from '../../routes/Hub.module.scss'; // TODO: use own module.scss

import { FontIcon } from "office-ui-fabric-react";
import React, { ReactNode, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

export interface Props {
	title: string;
	visible: boolean;
	renderMenu?: () => ReactNode;
	onClose: () => void;
}

export const SidePanel = (props: Props) => {
	useEffect(()=>{
		toggleBodyStyle(props.visible);
	}, [props.visible]);

	const toggleBodyStyle = (visible: boolean) => {
		if(visible) {
			window.scrollTo(0, 0);
			document.body.style['overflow'] = 'hidden';
		}
		else {
			document.body.style['overflow'] = '';
		}
	};

	return (
		<CSSTransition in={props.visible} timeout={2500} classNames='sidePanelOpen'>
			<div className={[styles.menuPage, props.visible ? styles.open : ''].join(' ')}>
				<div className={styles.headerContainer}>
					<div className={styles.headerInnerContainer}>
						<h1>{props.title}</h1>
						<div
							className={styles.menuButton}
							onClick={()=>{
								toggleBodyStyle(false);
								props.onClose();
							}}
						>
							<FontIcon iconName='Cancel' />
						</div>
					</div>
				</div>
				{props.renderMenu && props.renderMenu()}
			</div>
		</CSSTransition>
	)
}