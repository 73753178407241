import React, { ReactNode, useState, useEffect } from 'react';
import { IQuestionResult, IAnswerOptionResult } from '../../store/business/interfaces';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styles from './ResultGraph.module.scss';
import { formatFloat } from '../../shared/utils';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { useTranslation } from 'react-i18next';

export interface Props {
	question: IQuestionResult;
	renderAppendix?: (option: IAnswerOptionResult) => ReactNode;
}

export const ResultGraph = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const [options, setOptions] = useState<IAnswerOptionResult[]>([]);
	const [notVotedOption, setNotVotedOption] = useState<IAnswerOptionResult>(null);
	const [max, setMax] = useState(0);

	const { t } = useTranslation();

	useEffect(() => {
		setOptions([...props.question.answerOptions].sort((a, b) => b.votesCount - a.votesCount));
	}, [props.question]);

	useEffect(() => {
		if(survey.useVoteWeightInPercent) {
			setMax(100);
			setNotVotedOption({
				title: t('results.notVoted'),
				votesCount: (100 - options.reduce((sum, currentOption) => sum + currentOption.votesCount, 0))
			} as IAnswerOptionResult);
		}
		else {
			setMax(options[0]?.votesCount || 1);
		}
	}, [options]);

	if (!options || options.length === 0) {
		return <></>;
	}

	const getOptionResultInPercent = (option: IAnswerOptionResult): number => {
		let optionResultInPercent = 0;
		if(survey.useVoteWeightInPercent) {
			optionResultInPercent = option.votesCount;
		}
		else {
			const totalNumberOfVotes = options.reduce((sum, currentOption) => sum + currentOption.votesCount, 0);
			optionResultInPercent = option.votesCount * 100 / totalNumberOfVotes;
		}
		return (isNaN(optionResultInPercent) ? 0 : optionResultInPercent);
	};

	const getAttendeesVoteCountString = (option: IAnswerOptionResult): string => {
		if(!survey.useVoteWeightInPercent) return formatFloat(option.votesCount);
		
		const optionResultInPercent = getOptionResultInPercent(option);
		return `${formatFloat(optionResultInPercent)}`;
	};

	const getAttendeesVoteUnitString = (option: IAnswerOptionResult): string => {
		if(survey.useVoteWeightInPercent) return '%';

		return '';
	};

	const getBarStyle = (option: IAnswerOptionResult): React.CSSProperties => {
		return { width: `calc(${(option.votesCount * 100) / max}% - ${(option.votesCount * 65) / max}px)` };
	};

	const getBarColor = (option: IAnswerOptionResult): React.CSSProperties => {
		return { background: option.votesCount > 0 ? '#0F7681' : '#d1d1d1' };
	};

	return (
		<div className={styles.resultGraph}>
			<ul className={styles.items}>
				<TransitionGroup enter appear>
					{options.map((o, index) => {
						return (
							<CSSTransition
								key={index}
								classNames={{
									appear: styles.itemAppear,
									appearActive: styles.itemAppearActive,
									appearDone: styles.itemAppearDone,
								}}
								timeout={100}
							>
								<li className={styles.item}>
									<h6>{o.title}</h6>
									<div className={styles.flexContainer}>
										<span className={styles.bar} style={{ ...getBarStyle(o), ...getBarColor(o) }}></span>
										<span className={styles.scale}><span className={styles.value}>{getAttendeesVoteCountString(o)}</span>{getAttendeesVoteUnitString(o)}</span>
										{props.renderAppendix && props.renderAppendix(o)}
									</div>
								</li>
							</CSSTransition>
						);
					})}
					{notVotedOption && (
						<CSSTransition
							classNames={{
								appear: styles.itemAppear,
								appearActive: styles.itemAppearActive,
								appearDone: styles.itemAppearDone,
							}}
							timeout={100}
						>
							<li className={styles.item}>
								<h6>{notVotedOption.title}</h6>
								<div className={styles.flexContainer}>
									<span className={styles.bar} style={{ ...getBarStyle(notVotedOption), ...getBarColor(notVotedOption) }}></span>
									<span className={styles.scale}><span className={styles.value}>{getAttendeesVoteCountString(notVotedOption)}</span>{getAttendeesVoteUnitString(notVotedOption)}</span>
									{props.renderAppendix && props.renderAppendix(notVotedOption)}
								</div>
							</li>
						</CSSTransition>
					)}
				</TransitionGroup>
			</ul>
		</div>
	);
};
