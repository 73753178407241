import React from 'react';
import styles from './Menu.module.scss';
import { FontIcon } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';

export interface Props {
	onToggleMenu: () => void;
}

export const Menu = (props: Props) => {
	const { t } = useTranslation();
	return (
		<ul className={styles.menuList}>
			<li
				className={styles.menuListItem}
				onClick={() => {
					props.onToggleMenu();
				}}
			>
				<FontIcon iconName='Leave' />
				<span>{t('menu.signout')}</span>
			</li>
			<li className={styles.menuListItem}>
				<a href='http://votr.itacs.de' target='_blank' rel='noopener noreferrer' title={t('menu.votrinfo')}>
					<FontIcon iconName='Info' title={process.env.REACT_APP_VERSION} />
					<span>{t('menu.votrinfo')}</span>
				</a>
			</li>			
		</ul>
	);
};
