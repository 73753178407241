import { ISystemState } from './interfaces';
import { ISystemActionTypes, MESSAGE_ADD, MESSAGE_REMOVE, SYSTEM_LOADING, CONFIG_LOADED, MESSAGES_CLEAR } from './types';

const initialState: ISystemState = {
	loading: { config: false, login: false },
	messages: [],
	userId: undefined,
};

function innerReducer(state = initialState, action: ISystemActionTypes): ISystemState {
	switch (action.type) {
		case SYSTEM_LOADING: {
			return { ...state, loading: { ...state.loading, [action.loading]: action.isLoading } };
		}
		case MESSAGE_ADD: {
			return {
				...state,
				messages: state.messages.concat({
					created: action.created || new Date(),
					type: action.messageType,
					message: action.message,
					sticky: action.sticky,
				}),
			};
		}
		case MESSAGE_REMOVE: {
			return { ...state, messages: state.messages.filter((m, i) => !!m && i !== action.index) };
		}
		case MESSAGES_CLEAR: {
			return { ...state, messages: []};
		}
		case CONFIG_LOADED: {
			return {
				...state,
				title: action.title,
				reversion: action.reversion,
				userId: action.userId || undefined,
				apiBasePath: action.apiBasePath,
				apiKey: action.apiKey || undefined,
			};
		}
		default: {
			return state;
		}
	}
}

function systemReducer(state = initialState, action: ISystemActionTypes): ISystemState {
	const result = innerReducer(state, action);
	return result;
}

export default systemReducer;
