import { ISystemActionTypes, SYSTEM_LOADING, MESSAGE_ADD, MESSAGE_REMOVE, MESSAGES_CLEAR, CONFIG_LOADED } from './types';
import { MessageType, SystemLoading } from './enums';

export const systemLoading = (loading: SystemLoading, isLoading: boolean): ISystemActionTypes => {
	return {
		type: SYSTEM_LOADING,
		loading,
		isLoading,
	};
};

export const messageAdd = (type: MessageType, message: string | undefined, sticky: boolean): ISystemActionTypes => {
	return {
		type: MESSAGE_ADD,
		created: new Date(),
		messageType: type,
		message,
		sticky,
	};
};

export const messageRemove = (index: number): ISystemActionTypes => {
	return {
		type: MESSAGE_REMOVE,
		index,
	};
};

export const messagesClear = (): ISystemActionTypes => {
	return {
		type: MESSAGES_CLEAR,
	};
};

export const configLoaded = (
	title: string | null,
	reversion: number | null,
	userId: string | null,
	apiBasePath: string,
	apiKey: string | null
): ISystemActionTypes => {
	return {
		type: CONFIG_LOADED,
		title,
		reversion,
		userId,
		apiBasePath,
		apiKey,
	};
};
