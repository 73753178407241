import React, { useEffect, useState } from 'react';
import { IAnsweredOption, IQuestion, ISurvey } from '../store/business/interfaces';
import { CommandButton, TextField } from 'office-ui-fabric-react';
import styles from './VotingForm.module.scss';
import { LoadingSpinnerButton } from './atoms/LoadingSpinnerButton';
import { anyInArray } from '../shared/utils';
import { useTranslation } from 'react-i18next';
import { CheckboxWithCounter } from './atoms/CheckboxWithCounter';
import { SystemLoading } from '../store/system/enums';

export interface Props {
	survey: ISurvey;
	question: IQuestion;
	onSend: (answeredOptions: IAnsweredOption[], comment: string) => void;
	onUnVote: () => void;
	marginContainerName: string;
}

export const VotingForm = (props: Props) => {
	const [answeredOptions, setAnsweredOptions] = useState<IAnsweredOption[]>(props.question.answeredOptions);
	const [comment, setComment] = useState<string>(props.question.comment);
	const { t } = useTranslation();

	useEffect(() => {
		setAnsweredOptions(() => {
			return props.question.answeredOptions.map((ao) => ({ ...ao }));
		});
		setComment(props.question.comment);
	}, [props.question]);

	const onSelectAnswer = (answerId: string, amount: number) => {
		const answers = answeredOptions.filter((ao) => ao.id !== answerId);
		if (amount > 0) {
			answers.push({ id: props.question.answerOptions.find((o) => o.id === answerId)?.id, voteWeight: amount });
		}
		setAnsweredOptions(answers);
	};

	const maxVotes = props.question.votesPerMember * props.question.userVoteWeight;
	const remainingVotes =
		maxVotes - answeredOptions.map((ao) => ao.voteWeight).reduce((sum, current) => sum + current, 0);

	return (
		<div className={styles.votingFormContainer}>
			<div className={props.marginContainerName}>
				<div className={styles.votingFormChoice}>
					{props.question.votesPerMember > 0 ? (
						<>
							<div className={styles.hintTexts}>
								{props.question.security === 'private' ? (
									<span className={styles.hintText}>{t('form.secretQuestion')}</span>
								) : (
									<></>
								)}
								{(props.question.allowVotesSplitting && props.question.userVoteWeight > 1) ||
								(props.question.votesPerMember > 1 && props.question.answerOptions.length >= props.question.votesPerMember) ? (
									<span
										className={styles.hintText}
										dangerouslySetInnerHTML={{
											__html: t(`form.remainingVoices`, {
												remainingVotes: `${props.survey.useVoteWeightInPercent ? (props.question.votesPerMember - answeredOptions.length) : remainingVotes}`,
												maxVotes: `${props.survey.useVoteWeightInPercent ? props.question.votesPerMember : maxVotes}`,
											}),
										}}
									></span>
								) : (
									''
								)}
							</div>
							{props.question.kind === 'choice' && (
								<>
									{props.question.answerOptions.map((o) => (
										<CheckboxWithCounter
											disabled={props.question.hasAnswered}
											key={`${o.id}${!!answeredOptions.find((ao) => ao.id === o.id)?.voteWeight}`}
											option={o}
											answer={answeredOptions.find((ao) => ao.id === o.id)}
											userVoteWeight={props.question.userVoteWeight}
											initialVoteWeight={props.question.allowVotesSplitting ? 1 : props.question.userVoteWeight}
											remainingVotes={remainingVotes}
											remainingAnswers={
												// unlimited
												Number.MAX_SAFE_INTEGER
												// limit to answerOptions
												// props.question.votesPerMember > 1 ? props.question.votesPerMember - answeredOptions.length : 1
											}
											showCounter={props.question.allowVotesSplitting && props.question.userVoteWeight > 1}
											onChange={(answerId, amount) => {
												onSelectAnswer(answerId, amount);
											}}
										/>
									))}
								</>
							)}
							{props.question.kind === 'text' && (
								<TextField
									disabled={props.question.hasAnswered}
									placeholder={t('form.textAnswer')}
									defaultValue={props.question.comment}
									onChange={(e, v)=>{setComment(v);}}
									multiline={true}
									rows={4}
									className={styles.commentField}
									resizable={false}
									maxLength={5000}
								/>
							)}
						</>
					) : (
						<> {t('form.questionCannotAnswered')}</>
					)}
				</div>
				<LoadingSpinnerButton
					className={styles.button}
					loadingClassName={styles.loadingButton}
					text={t('form.vote')}
					onClick={() => (answeredOptions || comment) && props.onSend(answeredOptions, comment)}
					disabled={(!answeredOptions && !comment) || props.question.hasAnswered}
					exclude={[SystemLoading.Login, SystemLoading.Config]}
				/>
				{props.question.hasAnswered && props.question.security === 'public' ? (
					<>
						<CommandButton
							className={styles.button}
							text={t('form.changeVote')}
							disabled={!anyInArray(props.question.answeredOptions) && !props.question.comment}
							onClick={() => {
								props.onUnVote();
							}}
						/>
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
