import styles from '../../routes/Voting.module.scss'; // TODO: use own module.scss

import React from 'react';
import { IQuestion, IQuestionResult } from '../../store/business/interfaces';
import { SecurityIcon } from '../../shared/components/atoms/SecurityIcon';
import { QuestionKindIcon } from '../../shared/components/atoms/QuestionKindIcon';
import { VotesSplittingIcon } from '../../shared/components/atoms/VotesSplittingIcon';
import { Text } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';

export interface Props {
	question: IQuestion|IQuestionResult;
};

export const VotingHeader = (props:Props) => {
	const { t } = useTranslation();

	return (
		<div className={styles.votingHeader}>
			<div className={styles.marginContainer}>
				<div className={styles.marginContainerInner}>
					{!props.question && (
						<div className={styles.votingContentContainerNoVote}>{t('voting.noVoteActive')}</div>
					)}
					{props.question && (
						<>
							<div className={styles.flexContainer}>
								<SecurityIcon question={props.question} />
								<QuestionKindIcon question={props.question} />
								<VotesSplittingIcon question={props.question} />
							</div>
							<Text className={styles.votingQuestion}>{props.question.title}</Text>
							{props.question.description && (
								<div
									className={styles.votingDescription}
									dangerouslySetInnerHTML={{ __html: props.question.description }}
								></div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}