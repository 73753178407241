import { IBusinessState } from './interfaces';
import {
	IBusinessActionTypes,
	DATA_LOADING,
	SURVEY_LOADED,
	QUESTIONS_LOADED,
	MEMBER_LOADED,
	RESULT_LOADED,
} from './types';

const initialState: IBusinessState = {
	loading: {
		answer: false,
		question: false,
		survey: false,
	},
};

function innerReducer(state: IBusinessState, action: IBusinessActionTypes): IBusinessState {
	switch (action.type) {
		case DATA_LOADING: {
			return { ...state, loading: { ...state.loading, [action.loading]: action.isLoading } };
		}
		case SURVEY_LOADED: {
			return { ...state, survey: action.survey };
		}
		case QUESTIONS_LOADED: {
			return { ...state, questions: action.questions };
		}
		case RESULT_LOADED: {
			return { ...state, questionResult: action.questionResult };
		}
		case MEMBER_LOADED: {
			return { ...state, memberLogin: action.memberLogin, surveyCode: action.surveyCode, connection: action.connection };
		}
	}
	return state;
}

function businessReducer(state = initialState, action: IBusinessActionTypes): IBusinessState {
	const result = innerReducer(state, action);
	return result;
}

export default businessReducer;
